<template>
  <div class="question-form display--flex">
    <div class="card question-form__staff">
      <div class="card-header card-header-divider">Вопрос</div>
      <div class="card-body">
        <form action="#" @submit.prevent="submitQuestion" novalidate>
          <div
            class="
              question-form__inputs
              display--flex
              flex-column
              justify-content-center
            "
          >
            <div class="question-form__row1">
              <custom-input label="Текст" name="text" req v-model="form.text" />
            </div>
            <div
              class="question-form__row2 display--flex justify-content-between"
            >
              <custom-select-2
                label="Группа"
                name="group_id"
                v-model="form.group_id"
                :options="groupsOptions"
              />
              <custom-select-2
                label="Тип"
                name="question_type_id"
                v-model="form.question_type_id"
                :options="typesOptions"
              />
            </div>
            <div
              class="question-form__row3 display--flex justify-content-between"
            >
              <answers-block
                v-if="questionGroup"
                :type="questionGroup.view"
                :emotionType="questionGroup.view == 'icons' ? form.text : ''"
                @answerAdded="addAnswer"
                @answerDeleted="deleteAnswer"
                :formAnswers="form.answers"
                @clearAllAnswers="clearAllAnswers"
                @setEmotionAnswers="setEmotionAnswers"
                @changeEmotionValue="changeEmotionValue"
                @setIconsAnswers="setIconsAnswers"
              />
              <div class="question-form__number-col">
                <custom-input
                  label="Сортировка"
                  name="sort"
                  req
                  type="number"
                  v-model="form.sort"
                />
                <custom-input
                  label="Количество разрешенных ответов"
                  name="answers_num"
                  req
                  type="number"
                  v-model="form.answers_num"
                  :minValue="1"
                />
                <custom-input
                  label="Количество обязательных правильных ответов"
                  name="valid_answers_num"
                  req
                  type="number"
                  :minValue="1"
                  v-model="form.valid_answers_num"
                />
              </div>
            </div>
          </div>
          <div
            class="
              question-form__btns
              mt-25
              mb-10
              display--flex
              justify-content-end
            "
          >
            <button type="submit" class="btn btn-accent mr-10">
              Сохранить
            </button>
            <router-link to="/test/question" class="btn btn-w"
              >Назад</router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useErrors from "../../../mixins/useErrors";
import { reactive, ref } from "@vue/reactivity";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import CustomInput from "../../../components/Forms/Fields/CustomInput.vue";
import standartError from "../../../mixins/standartError";
import AnswersBlock from "./AnswersBlock/AnswersBlock.vue";

export default {
  components: { CustomSelect2, CustomInput, AnswersBlock },
  name: "question-form",
  setup() {
    const store = useStore(),
      route = useRoute(),
      { setErrors, clearCustomErrors } = useErrors();
    const form = reactive({
      text: "",
      sort: null,
      group_id: null,
      question_type_id: null,
      answers_num: null,
      valid_answers_num: null,
      answers: [],
    });
    const groups = computed(() => store.state.test.questionGroups);
    const types = computed(() => store.state.test.questionTypes);
    const group = computed(() => store.state.group.group);
    const groupsOptions = ref([]),
      typesOptions = ref([]);
    const question = computed(() => store.state.test.question);
    const questionGroup = computed(() => store.state.test.questionGroup);

    onBeforeMount(() => {
      store.dispatch("test/getQuestionGroups").then(() => {
        groupsOptions.value = groups.value.map((group) => {
          return { id: group.id, text: group.text };
        });
      });
      store.dispatch("test/getQuestionTypes").then(() => {
        typesOptions.value = types.value.map((type) => {
          return { id: type.id, text: type.name };
        });
      });
      if (route.params.id) {
        store.dispatch("test/getQuestion", route.params.id).then(() => {
          form.text = question.value.text;
          form.sort = question.value.sort;
          form.group_id = String(question.value.group_id);
          form.question_type_id = String(question.value.question_type_id);
          form.answers_num = question.value.answers_num;
          form.valid_answers_num = question.value.valid_answers_num;
          form.answers = question.value.answers;
          if (form.group_id)
            store.dispatch("test/getQuestionGroup", form.group_id);
        });
      }
    });

    const addAnswer = (answer) => {
      form.answers.push({
        sort: answer.sort,
        text: answer.text,
        value: answer.value,
        instruction: answer.instruction,
      });
    };
    const deleteAnswer = (answer) => {
      form.answers = form.answers.filter(
        (answ) => answ.sort != answer.sort && answ.text != answer.text
      );
    };
    const changeEmotionValue = (index, value) => {
      form.answers.some((answ) => {
        if (answ.sort == index) {
          answ.value = value;
          return true;
        } else return false;
      });
    };
    const setEmotionAnswers = (answers) => {
      form.answers = answers;
    };
    const clearAllAnswers = () => {
      form.answers = [];
    };
    const setIconsAnswers = (answers, emotion) => {
      form.text = emotion;
      form.answers = answers;
    };

    let submitQuestion = () => {
      if (route.params.id) {
        store
          .dispatch("test/updateQuestion", route.params.id, { ...form })
          .catch(standartError(setErrors, clearCustomErrors));
      } else {
        store
          .dispatch("test/createQuestion", { ...form })
          .catch(standartError(setErrors, clearCustomErrors));
      }
    };

    watch(
      () => form.group_id,
      () => {
        if (form.group_id)
          store.dispatch("test/getQuestionGroup", form.group_id);
      }
    );

    return {
      form,
      submitQuestion,
      group,
      addAnswer,
      deleteAnswer,
      question,
      changeEmotionValue,
      setEmotionAnswers,
      clearAllAnswers,
      groups,
      types,
      groupsOptions,
      typesOptions,
      setIconsAnswers,
      questionGroup,
    };
  },
};
</script>

<style lang="scss" scoped>
.question-form {
  padding: 0 0 10px 0;
  display: flex;
  width: 85%;
  margin: auto;
  justify-content: center;
  flex-direction: column;

  &__row1 {
    .form-group {
      display: grid;
      grid-template-columns: 1fr 7.4fr;
    }
  }

  &__row2 {
    .form-group {
      flex: 0 0 50%;
    }
  }
  &__answer-section {
    flex: 1 0 52%;
    padding-right: 25px;
  }

  &__number-col {
    flex: 1 0 48%;
    padding-left: 10px;
    padding-top: 55px;

    .form-group {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
  }

  .form-group {
    margin: 25px -15px;
  }

  .card-body {
    padding: 20px 50px;
  }

  @media (max-width: 1630px) {
    width: 100%;
  }

  @media (max-width: 1082px) {
    &__row1 {
      .form-group {
        display: flex;
      }
    }
    &__row2 {
      flex-direction: column;
      .form-group {
        flex: 1;
      }
    }
  }

  @media (max-width: 973px) {
    .card-body {
      padding: 20px 25px;
    }
    .card-header {
      margin: 0 25px;
    }
    .form-group {
      margin: 5px -15px;
    }

    &__row3 {
      flex-direction: column-reverse;
    }

    &__number-col {
      flex: 0 0 21%;
      padding-left: 0;
      .form-group {
        display: flex;
      }
    }

    &__answer-section {
      padding-right: 0;
    }
  }

  @media (max-width: 766px) {
    &__number-col {
      flex: 1;
    }
  }
}
</style>