<template>
  <div class="question-form__answer-section answer-section">
    <div class="answer-section__header">Ответы</div>
    <div class="answer-section__choice">
      <div class="table-choice" v-if="type === 'table'">
        <custom-select-2
          v-model="template"
          label="Шаблон ответов"
          name="answer-template"
          :options="['Шкала', 'Настраиваемый']"
          @select="() => {}"
        />
        <label for="">При смене все сохраненные ответы потеряются!</label>
      </div>
      <div class="icons-choice flex flex-column" v-if="type === 'icons'">
        <custom-select-2
          v-model="answCount"
          label="Количество иконок"
          name="count-answers"
          :options="['1', '2', '3', '4', '5']"
          @select="() => {}"
        />
        <label for="count-answers">Укажите 5 для вывода всех эмоций</label>
        <custom-select-2
          name="emotion-icon"
          label="Эмоция"
          v-model="emotionIcon"
          :options="iconsOptions"
        />
      </div>
    </div>

    <div
      class="answer-section__form"
      v-if="template === 'Настраиваемый' && type !== 'icons'"
    >
      <label for="answ-text-input">Текст</label>
      <input
        v-model="answer.text"
        id="answ-text-input"
        class="custom-input"
        type="text"
      />
      <label for="answ-instruct-input">Подсказка</label>
      <input
        v-model="answer.instruction"
        id="answ-instruct-input"
        class="custom-input mb-10"
        type="text"
      />
      <div class="display--flex answer-section__numbers align-item-center">
        <div class="display--flex flex-column mr-10">
          <label for="answ-value-input">Значение</label>
          <input
            v-model="answer.value"
            id="answ-value-input"
            class="custom-input"
            type="number"
          />
        </div>
        <div class="display--flex flex-column">
          <label for="answ-sort-input">Сортировка</label>
          <input
            v-model="answer.sort"
            id="answ-sort-input"
            class="custom-input"
            type="number"
          />
        </div>
        <button type="button" @click="addAnswer" class="btn btn-accent">
          Добавить
        </button>
      </div>
    </div>
    <div
      class="answer-section__list"
      v-if="template === 'Настраиваемый' && type !== 'icons'"
    >
      <div
        v-for="answ in formAnswers"
        :key="answ.sort"
        class="answer-section__item answer-item display--flex flex"
      >
        <div class="flex flex-column flex-1">
          <div class="display--flex align-item-center justify-content-start">
            <div class="answer-item__sort">{{ answ.sort }}</div>
            <div class="answer-item__text">{{ answ.text }}</div>
          </div>
          <div class="answer-item__details">
            <div class="answer-item__value">Значение: {{ answ.value }}</div>
            <div class="answer-item__instruction">
              {{ answ.instruction }}
            </div>
          </div>
        </div>
        <div class="flex align-item-center">
          <button type="button" @click="deleteAnswer(answ)">
            <i class="fal fa-minus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="answer-section__list" v-if="template === 'Шкала'">
      <div
        v-for="index in 5"
        :key="index"
        class="answer-section__item answer-item display--flex flex"
      >
        <div class="flex flex-column flex-1">
          <div class="display--flex align-item-center justify-content-start">
            <div class="answer-item__sort">{{ index }}</div>
            <div class="answer-item__text" v-if="index === 1">
              <input class="custom-input" v-model="firstEmotion" />
            </div>
            <div class="answer-item__text" v-if="index === 5">
              <input class="custom-input" v-model="secondEmotion" />
            </div>
            <div class="answer-item__text" v-if="index > 1 && index < 5">
              {{ emotions[index - 1] }}
            </div>
          </div>
          <div class="answer-item__details">
            <div class="answer-item__value">
              Значение:
              <input
                id="emotion-sort-input"
                class="custom-input"
                type="number"
                :value="index - 1"
                :data-index="index - 1"
                @input="changeEmotionValue"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="answer-section__list" v-if="type === 'icons' && emotionIcon">
      <div
        v-for="index in parseInt(answCount)"
        :key="index"
        class="answer-section__item answer-item display--flex flex"
      >
        <div class="flex flex-column flex-1">
          <div class="display--flex align-item-center justify-content-start">
            <div class="answer-item__sort">{{ index }}</div>
            <div class="answer-item__icon">
              <img :src="getIcon(index)" alt="Иконка" />
            </div>
          </div>
          <div class="answer-item__details">
            <div class="answer-item__value">
              Значение:
              <input
                id="emotion-sort-input"
                class="custom-input"
                type="number"
                :value="index - 1"
                :data-index="index - 1"
                @input="changeEmotionValue"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRef } from "@vue/reactivity";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import { useRoute } from "vue-router";
import { watch } from "@vue/runtime-core";
import emotionsIconsTable from "../../../../mixins/dictionaries/emotionIconsTable";
export default {
  components: { CustomSelect2 },
  name: "answers-block",
  props: {
    formAnswers: {
      type: Object,
    },
    type: {
      type: String,
      default: "table",
    },
    emotionType: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const answer = reactive({
      text: "",
      sort: null,
      value: null,
      instruction: "",
    });
    const emotionAnswers = reactive({});
    const firstEmotion = ref(""),
      secondEmotion = ref(""),
      emotionsTable = emotionsIconsTable;
    const route = useRoute();
    let template = ref("Настраиваемый"),
      emotions = [
        "Не выражена",
        "Слабо",
        "Средне",
        "Выше среднего",
        "Выражена сильно",
      ],
      answCount = ref("5"),
      emotionIcon = toRef(props, "emotionType"),
      iconsOptions = [
        "Отвращение",
        "Удивление",
        "Страх",
        "Печаль",
        "Интерес",
        "Радость",
        "Доверие",
      ];

    const addAnswer = () => {
      if (answer.text && answer.sort && answer.value && answer.instruction) {
        context.emit("answerAdded", answer);
        answer.text = "";
        answer.sort = null;
        answer.value = null;
        answer.instruction = null;
      }
    };
    const deleteAnswer = (answer) => {
      context.emit("answerDeleted", answer);
    };
    const changeEmotionValue = (e) => {
      context.emit(
        "changeEmotionValue",
        e.currentTarget.dataset.index,
        e.currentTarget.value
      );
    };
    const emitSetEmotionAnswers = () => {
      const answers = [
        {
          text: firstEmotion.value,
          sort: 0,
          value: 0,
          instruction: "",
        },
        { text: emotions[1], sort: 1, value: "1", instruction: "" },
        { text: emotions[2], sort: 2, value: "2", instruction: "" },
        { text: emotions[3], sort: 3, value: "3", instruction: "" },
        {
          text: secondEmotion.value,
          sort: 4,
          value: "4",
          instruction: "",
        },
      ];
      context.emit("setEmotionAnswers", answers);
    };

    const getIcon = (index) => {
      if (index === 1) {
        return require("@/assets/images/test-icons/neutral.jpg");
      } else {
        return require(`@/assets/images/test-icons/${
          emotionsTable[emotionIcon.value]
        }${index - 1}.jpg`);
      }
    };

    watch(
      () => emotionIcon.value,
      () => {
        if (emotionIcon.value) {
          const answers = [];
          for (let i = 0; i < answCount.value; i++) {
            answers.push({
              text: `${i == 0 ? "neutral" : `${emotionIcon.value}${i}`}`,
              sort: i,
              value: String(i),
              instruction: "",
            });
          }
          let emotion = iconsOptions.find(
            (option) => option.id === emotionIcon.value
          )?.text;
          context.emit("setIconsAnswers", answers, emotion);
        }
      }
    );

    watch(template, () => {
      if (template.value == "Шкала") {
        emitSetEmotionAnswers();
      } else {
        context.emit("clearAllAnswers");
      }
    });
    watch([firstEmotion, secondEmotion], () => {
      emitSetEmotionAnswers();
    });

    return {
      answer,
      addAnswer,
      deleteAnswer,
      template,
      route,
      emotions,
      emotionAnswers,
      changeEmotionValue,
      answCount,
      emotionIcon,
      getIcon,
      emitSetEmotionAnswers,
      firstEmotion,
      secondEmotion,
      iconsOptions,
      emotionsTable,
    };
  },
};
</script>

<style scoped lang="scss">
.answer-section {
  padding-top: 25px;

  label {
    font-weight: 400;
    font-size: 14px;
  }

  &__header {
    background-color: var(--main-color);
    color: white;
    font-weight: 400;
    padding: 15px 20px;
  }

  &__form {
    border: 1px solid var(--cream-color);
    border-bottom: 0;
    padding: 10px;
  }

  &__numbers {
    margin: 10px 0;

    .btn {
      align-self: flex-end;
      margin-left: 10px;
      margin-bottom: 2px;
      flex: 1 1 30%;
    }
  }

  &__choice {
    border: 1px solid var(--cream-color);
    padding: 10px;

    label {
      font-size: 13px;
      color: grey;
    }
  }

  &__list {
    border: 1px solid var(--cream-color);
    height: 300px;
    overflow-y: auto;
  }

  &__item {
    padding: 5px 15px;
    background-color: #fff;
    display: flex;
    border-bottom: 1px solid var(--cream-color);
    a {
      display: block;
      color: var(--common-text-color);
      width: 100%;
      height: 100%;
      word-break: break-word;
    }

    button {
      background-color: transparent;
      padding: 6px 12px;
      font-size: 18px;
    }

    &:nth-of-type(odd) {
      background-color: #f5f5f5;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
  @media (max-width: 600px) {
    &__numbers {
      flex-direction: column;

      & > div {
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

.answer-item {
  &__sort {
    margin-right: 8px;
  }
  &__text {
    flex: 1;
    word-break: break-word;
    margin-right: 2px;
    .custom-input {
      padding: 5px;
    }
  }
  button {
    padding: 6px 8px;
    i {
      font-size: 21px;
    }
  }
  &__details {
    margin-top: 5px;
    font-size: 13px;
    word-break: break-word;
  }
  &__icon {
    img {
      height: 55px;
    }
  }
}
</style>